import { useEffect, useState } from 'react'
import useSelectedBundle from 'hooks/useSelectedBundle'
import useSelectedClasses from 'hooks/useSelectedClasses'
import { useNavigate } from 'react-router-dom'
import { Class } from 'types/shopScheduleTypes'
import ConfirmClassTab from 'views/ScheduleClass/components/ConfirmClassTab'
import { submitFinalScheduling } from 'views/ScheduleBundle/utils/utils'
import { reportToSentry } from 'utils/reportToSentry'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { scrollToTop } from 'utils'
import TermsChecker from 'components/TermsChecker'
import { BUNDLE_SELECTION_BASE_PATH, INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'
import useAuthStore from 'stores/auth'
import useHerHubStore from 'stores/herHub'

type ConfirmPageProps = {
  nextPage?: () => void;
  classes: Class[];
  removeTimes: ( _classes: Class[] ) => void;
  toggleSkipScheduling: ( _classInfo: Class ) => void;
}

function Confirm({
  nextPage,
  classes,
  removeTimes,
  toggleSkipScheduling

}: ConfirmPageProps ): JSX.Element {

  const [ loading, setLoading ] = useState<boolean>( false )
  const [ schedulingError, setSchedulingError ] = useState<string>( `` )
  const [ isTermChecked, setIsTermChecked ] = useState<boolean>( false )
  const [ termsError, setTermsError ] = useState<string>( `` )

  const { selectedBundle } = useSelectedBundle()
  const { addRecentReschedule, hideClassFromNotifications } = useHerHubStore()
  const navigate = useNavigate()

  const { buildAuthorizer } = useAuthStore()
  const authHeader = buildAuthorizer()

  useEffect( () => {
    if ( !classes?.length ) navigate( `${selectedBundle?.classes?.length ? BUNDLE_SELECTION_BASE_PATH : INDIVIDUAL_SELECTION_BASE_PATH}${window.location.search}` )
    else if ( sessionStorage.getItem( `has_scheduled` ) === `true` && nextPage ) nextPage()
  }, [] )

  const handleOnClick = async () => {
    if ( !isTermChecked ) return setTermsError( `Please accept terms and conditions` )

    const scheduledClasses = classes.filter( ( classDetails: Class ) => {
      return Boolean( classDetails.selected_timeslot )
    })
    if ( !scheduledClasses?.length ) return setSchedulingError( `Oops... You have not selected a time for every class please select a time for every class. Please try again or contact customer service at 888-732-3979 if the issues persists.` )

    setLoading( true )
    const schedulingResponse = await submitFinalScheduling( scheduledClasses, authHeader )
      .catch( ( error: Error ) => {
        setLoading( false )
        reportToSentry( new Error( `Shop and Schedule: An error occurred submitting a patients final scheduling`, {
          cause: error
        }), {
          authHeader,
          classes: JSON.stringify( classes )
        })

        return setSchedulingError( `Oops... We encountered an error scheduling your classes. Please try again or contact customer service at 888-732-3979 if the issue persists.` )
      })

    setLoading( false )

    if ( nextPage && schedulingResponse?.data?.success && schedulingResponse?.meta?.status === `Accepted` ) {
      useSelectedBundle.destroy()
      useSelectedClasses.destroy()
      classes.filter( ( classDetails: Class ) => {
        return Boolean( classDetails.selected_timeslot )
      }).forEach( ( _class: any ) => {
        const ts = _class.selected_timeslot
        hideClassFromNotifications( ts.class_pk )
        addRecentReschedule({
          class_sku: _class.class_sku,
          class_title: _class.class_title,
          ...ts
        })
      })
      sessionStorage.setItem( `has_scheduled`, `true` )

      if ( window.location !== window?.parent?.location ) window?.parent?.postMessage( `Schedule Success`, `*` )
      else nextPage()

      return scrollToTop()
    }

    setSchedulingError( `Oops... We encountered an error scheduling your classes. Please try again or contact customer service at 888-732-3979 if the issue persists.` )
    reportToSentry( new Error( `Shop and Schedule: An error occurred submitting a patients final scheduling` ), {
      authHeader,
      classes: JSON.stringify( classes )
    })
  }

  if ( loading ) return <AppointmentsLoadingBox message={`Please hold tight while we schedule your classes...`} />

  return (
    <div className="flex flex-col relative justify-center items-center overflow-hidden max-w-lg mx-auto px-5">
      <h1 className="font-header text-3xl text-center my-10">{`Please Review & Confirm`}</h1>
      {
        selectedBundle?.bundle_title &&
        <div className="bg-secondary mb-10 rounded-t-lg font-light w-full">
          <p className="text-lg md:text-xl text-white text-center py-1">{selectedBundle.bundle_title}</p>
        </div>
      }
      <div className="flex flex-col justify-center items-center px-3">
        {
          classes?.length &&
          classes.filter( ( classDetails: Class ) => {
            return Boolean( classDetails.selected_timeslot ) || classDetails.skip_scheduling
          }).map( ( classDetails: Class ) => {
            return (
              <ConfirmClassTab
                classInfo={classDetails}
                key={classDetails.class_id}
                displayTimezone
                allowRemove
                removeTimes={removeTimes}
                toggleSkipScheduling={toggleSkipScheduling}
              />
            )
          })

        }
      </div>
      <div className="pb-10 px-2 flex justify-center items-center w-full flex-col bg-white max-w-xl">
        {
          !selectedBundle?.classes?.length && classes?.length < 6 &&
          <p
            onClick={() => {
              return navigate( `${INDIVIDUAL_SELECTION_BASE_PATH}${window.location.search}` )
            }}
            className="a"
          >
            {`Add More Classes`}
          </p>
        }
        <div className="max-w-md flex flex-col items-center">
          <TermsChecker
            name="authorize_aeroflow"
            type="checkbox"
            value={isTermChecked}
            containerClassname={`pb-2 px-2 bg-pink-3 bg-opacity-50 ${!termsError && `mb-7`}`}
            onClick={() => {
              setTermsError( `` )
              setIsTermChecked( !isTermChecked )
            }}
          />
          {termsError && <p className="text-error text-center mt-2 mb-7">{termsError}</p>}
        </div>
        <div className="mx-5 md:mx-16">
          <button
            onClick={handleOnClick}
            className="primary-button"
          >
            {`Confirm`}
          </button>
        </div>
        {
          schedulingError &&
          <p className="text-error my-5 font-light text-center text-base md:text-lg">{schedulingError}</p>
        }
      </div>
    </div>
  )
}

export default Confirm