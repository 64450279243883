import useSelectedClasses from 'hooks/useSelectedClasses'
import { useEffect, useState } from 'react'
import { Class, FormattedAppointments, TimeSlot } from 'types/shopScheduleTypes'
import { reportToSentry } from 'utils/reportToSentry'
import { fetchClassTimeslots } from 'views/ScheduleIndividualClasses/utils/utils'
import useAuthStore from 'stores/auth'

export const useFormattedAppointments = ( classes: Class[], timezone: string | null, seriesReference: Map<number, Map<number, string>>, excludeDays: Set<string> ): {
  formattedTimeSlots: FormattedAppointments,
  error: string,
  loading: boolean
} => {
  const [ formattedTimeSlots, setFormattedTimeSlots ] = useState<FormattedAppointments>({})
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ error, setError ] = useState<string>( `` )

  const { buildAuthorizer } = useAuthStore()
  const authorization = buildAuthorizer()

  const { selectedClasses, addTimeslots } = useSelectedClasses()

  const defaultErrorMessage = `Oops... We ran into an issue grabbing the available times for your class.`

  useEffect( () => {
    const controller = new AbortController()


    if ( classes?.length ) {
      // check if we have already fetched the timeslots for this class in this timezone
      const needToFetchTimeslots = classes?.some( classInfo => {
        return !classInfo[`${timezone}_timeslots`]
      })

      // if we need to fetch the timeslots then fetch them
      if ( timezone && needToFetchTimeslots ) {
        // we should only ever need to fetch 2 classes here 1 pre-reg and 1 legacy at the most
        const getTimeslots = async ( classInfo: Class, index: number, builtTimeslots: FormattedAppointments ) => {
          return await handleGetClassTimeslots( classInfo, index, builtTimeslots, controller.signal )
        }
        getTimeslots( classes[0], 0, {}).then( timeslots => {
          // fetch timeslots for second class if there is one
          if ( classes?.length === 2 ) getTimeslots( classes[1], 1, timeslots )
        })
      } else if ( !needToFetchTimeslots ) {
        setLoading( false )

        let builtTimeslots: FormattedAppointments = {}

        // we already have the timeslots stored so format the timeslots
        classes?.forEach( classInfo => {
          builtTimeslots = formatTimeSlots( classInfo, classInfo[`${timezone}_timeslots`], builtTimeslots )
        })
      }
    }


    return () => controller.abort()
  }, [ timezone ] )


  const formatTimeSlots = ( classInfo: Class, timeSlots: TimeSlot[], builtTimeslots: FormattedAppointments ) => {
    setError( `` )
    const buildFormattedTimeSlots: FormattedAppointments = {}
    timeSlots.forEach( ( timeSlot: TimeSlot ) => {
      const dateKey = timeSlot.timeslot_exact_timestamp.substring( 0, 10 )
      // this logic is in place to check if the user has already selected a class with the same date
      if ( excludeDays.has( dateKey ) ) return
      // this logic is in place to not allow users to schedule series classes out of order
      const curSeriesRef = classInfo?.class_series_pk ? seriesReference.get( classInfo.class_series_pk ) : null
      if ( curSeriesRef && curSeriesRef?.has( classInfo.class_sequence - 1 ) && timeSlot.timeslot_exact_timestamp.localeCompare( curSeriesRef.get( classInfo.class_sequence - 1 ) ?? `` ) < 0 ) return
      if ( buildFormattedTimeSlots[dateKey] ) {
        const appendSlot = buildFormattedTimeSlots[dateKey]
        appendSlot.push({
          start_time: timeSlot.timeslot_exact_timestamp,
          duration_minutes: timeSlot.duration_minutes,
          begin_time_pretty: timeSlot.begin_time_pretty,
          event_instance_id: timeSlot.event_instance_id
        })
        buildFormattedTimeSlots[dateKey] = appendSlot
      } else buildFormattedTimeSlots[dateKey] = [{
        start_time: timeSlot.timeslot_exact_timestamp,
        duration_minutes: timeSlot.duration_minutes,
        begin_time_pretty: timeSlot.begin_time_pretty,
        event_instance_id: timeSlot.event_instance_id
      }]
    })
    const updatedBuiltTimeslots = {
      ...buildFormattedTimeSlots,
      ...builtTimeslots
    }
    setFormattedTimeSlots( updatedBuiltTimeslots )

    return updatedBuiltTimeslots
  }

  const handleGetClassTimeslots = async ( classInfo: Class, index: number, builtTimeslots: FormattedAppointments, signal: AbortSignal ): Promise<FormattedAppointments> => {
    try {
      setError( `` )
      setLoading( true )
      const data = await fetchClassTimeslots( classInfo.class_id, timezone, authorization, signal )
      if ( index === classes.length - 1 ) setLoading( false )
      if ( data?.data && data?.meta?.status === `OK` ) {
        const status = addTimeslots( data.data, timezone ?? `US/Eastern` )
        if ( !status ) {
          reportToSentry( new Error( `Shop and Schedule: Error getting class timeslots` ), {
            selectedClasses: JSON.stringify( selectedClasses )
          })
          setError( defaultErrorMessage )

          return {}
        }

        const updatedTimeslots = formatTimeSlots( classInfo, data.data?.timeslots, builtTimeslots )

        setLoading( false )

        return updatedTimeslots
      } else {
        reportToSentry( new Error( `Shop and Schedule: Error getting class timeslots`, {
          cause: error
        }), {
          selectedClasses: JSON.stringify( selectedClasses )
        })
        setError( defaultErrorMessage )
        setLoading( false )

        return {}
      }
    } catch ( error: any ) {
      if ( error.name !== `AbortError` ) {
        setLoading( false )
        reportToSentry( new Error( `Shop and Schedule: Error getting class timeslots`, {
          cause: error
        }), {
          selectedClasses: JSON.stringify( selectedClasses )
        })
        setError( defaultErrorMessage )
      }

      return {}
    }
  }

  return {
    formattedTimeSlots,
    error,
    loading
  }
}