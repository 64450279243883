import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { SchedulingNavbar } from 'components/SchedulingNavbar'
import { Bundle, Class, TimeSlot } from 'types/shopScheduleTypes'
import CurrentClassTab from './components/CurrentClassTab'
import DatePicker from 'components/DatePicker'
import SummarySection from './components/SummarySection'
import { useFormattedAppointments } from './hooks/useFormattedAppointments'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { scrollToTop } from 'utils'
import useSelectedClasses from 'hooks/useSelectedClasses'

type ScheduleClassProps = {
  step: number;
  totalSteps: number;
  nextPage?: () => void;
  prevPage?: () => void;
  classInfo: Class;
  bundleDetails?: Bundle;
  excludeDays: Set<string>;
  handlePartialSchedule: ( _selectedSlot: TimeSlot ) => void;
  handleGetClasses?: ( _classId: string ) => Class | null;
  timezone: string | null;
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
  seriesReference: Map<number, Map<number, string>>;
  removeTimes: ( _classes: Class[] ) => void;
  toggleSkipScheduling: ( _classInfo: Class ) => void;
}

function ScheduleClass({
  step,
  classInfo,
  totalSteps,
  bundleDetails,
  handlePartialSchedule,
  nextPage,
  timezone,
  setTimezone,
  seriesReference,
  excludeDays,
  removeTimes,
  toggleSkipScheduling
}: ScheduleClassProps ): JSX.Element {

  const [ startTime, setStartTime ] = useState<string>( `` )
  const { clearDayFromCal, selectedClasses } = useSelectedClasses()

  const allClasses = useMemo( () => {
    if ( bundleDetails?.classes?.length ) {
      return bundleDetails?.classes.filter( ( bundleClass ) => {
        return bundleClass.class_title?.trim() === classInfo.class_title?.trim()
      })
    } else {
      return selectedClasses.filter( ( selectedClass: Class ) => {
        return selectedClass.internal_class_title.trim() === classInfo.internal_class_title.trim()
      })
    }
  }, [ selectedClasses, bundleDetails ] )


  const {
    formattedTimeSlots,
    error,
    loading
  } = useFormattedAppointments( allClasses, timezone, seriesReference, excludeDays )

  const handleTimeClick = ( clickedTime: { start_time: string }) => {
    if ( !startTime || clickedTime.start_time !== startTime ) return setStartTime( clickedTime.start_time )
  }

  const nextNavigate = () => {
    scrollToTop()
    if ( nextPage ) nextPage()
  }

  const handleNext = () => {
    allClasses.forEach( ( classInfo: Class ) => {
      const selectedSlot = classInfo[`${timezone}_timeslots`].find( ( timeSlot: TimeSlot ) => {
        return timeSlot.timeslot_exact_timestamp === startTime
      })
      if ( selectedSlot ) {
        clearDayFromCal()
        handlePartialSchedule( selectedSlot )
        nextNavigate()
      }
    })
  }

  const handleSkipClass = () => {
    toggleSkipScheduling( classInfo )
    nextNavigate()
  }

  if ( error ) return <SchedulingErrorBlock message={error} />

  if ( loading ) return <AppointmentsLoadingBox
    message={`Please hold tight while we grab the available class dates and times...`}
  />

  return (
    <div className="flex flex-col items-center justify-center w-full pb-32">
      <p className="text-3xl md:text-4xl font-header mt-5 mb-2 text-center">{`Schedule Your Classes`}</p>
      <p className="text-lg md:text-2xl font-light">{`Choose a day and a time`}</p>
      <div className="my-7 relative w-full max-w-2xl">
        <CurrentClassTab classInfo={classInfo} bundleDetails={bundleDetails} />
      </div>
      {
        Object.keys( formattedTimeSlots ).length ?
          <DatePicker
            timeZone={timezone}
            setTimeZone={setTimezone}
            appointments={formattedTimeSlots}
            startTime={startTime}
            onMonthChange={() => {
              return setStartTime( `` )
            }}
            handleTimeClick={handleTimeClick}
          />
          :
          <div className="my-16 flex items-center gap-5 justify-center flex-col">
            <p className="max-w-lg text-error text-center text-lg md:text-xl font-light">
              {`There are currently no appointment slots for this class. Please select another class...`}
            </p>
            <button
              onClick={nextPage}
              className="primary-button"
            >
              {`Skip Class`}
            </button>
          </div>
      }
      <SummarySection
        bundleDetails={bundleDetails}
        removeTimes={removeTimes}
        step={step}
        toggleSkipScheduling={toggleSkipScheduling}
      />
      <SchedulingNavbar
        step={step}
        total={totalSteps}
        nextPage={handleNext}
        disabled={!startTime}
        handleSkipClass={bundleDetails && handleSkipClass}
      />
    </div>

  )
}

ScheduleClass.propTypes = {
  classInfo: PropTypes.object,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
  nextPage: PropTypes.func,
  prevPage: PropTypes.func
}

export default ScheduleClass